<template>
  <div>
    <topBarY />
    <div class="bg">
      <img src="../../../assets/common/pic/bgss.jpg" alt="">
    </div>
    <div class="switchBar">
      <div class="tab contact">AGV移动机器人覆盖漫游传输</div>
    </div>
    <div class="line"></div>
    <div class="switchContiner">
      <div class="continer contactContiner">
        <div class="txt">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;工厂自动化的普及以及智能工厂的建设，AGV的落地使用已经成为了一些智能工厂的标配生产工具，
          LINKOM无线基站搭配多功能控制器及智能维护APP以配合多家智能AGV机器人公司诸多项目落地，
          以其无线切换的漫游及优化管理功能保障了实施机器人作业的稳定性及便利性。
          LINKOM的无线基站及控制器已广泛应用于AGV机器人企业、仓储覆盖等多种应用场景。
        </div>
        <div class="pic">
          <img src="../../../assets/solution/5.png" alt="">
        </div>
      </div>
    </div>
    <baseBar />
  </div>
</template>
  
<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
  name: 'Robot',
  components: {
    topBarY,
    baseBar
  },
  created() {
    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁止键盘F12键
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault(); // 如果按下键F12,阻止事件
        }
      });
    });
  }
}
</script>
  
<style scoped>
.bg {
  position: relative;
  width: 1920px;
  height: 480px;
  overflow: hidden;
}

.bg img {
  position: absolute;
  top: -100px;
  width: 1920px;
}

.switchBar {
  width: 1920px;
  height: 70px;
  background-color: #fff;
  text-align: center;
}

.switchBar .tab {
  display: inline-block;
  margin-right: 30px;
  margin-top: 15px;
  border-radius: 5px;
  width: 250px;
  height: 40px;
  background-color: #c3a35d;
  color: #f5f5f5;
  font-family: 'Microsoft Yahei';
  font-size: 16px;
  line-height: 40px;
}

.line {
  margin: 0 auto;
  width: 1400px;
  height: 1px;
  background-color: #c3a35d;
}

.switchContiner {
  width: 1920px;
  padding: 20px;
  background-color: #f7f7f7;
}

/* 关于宁讯 */
.switchContiner .contactContiner {
  margin: 0 auto;
  padding: 40px;
  padding-left: 300px;
  padding-right: 300px;
  width: 1400px;
  height: 460px;
  border-radius: 5px;
  background-color: #fff;
}

.switchContiner .contactContiner .txt {
  font-size: 18px;
  line-height: 34px;
}

.switchContiner .contactContiner .pic {
  margin-top: 40px;
  text-align: center;
}

.switchContiner .contactContiner .pic img {
  width: 700px;
}
</style>
